.skills {
  padding: 4rem 0 4rem 0;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    font-size: 2rem;
    color: #fff9f0;
    margin-bottom: 2rem;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__languages {
    width: 80%;
    background-color: #ededed;
    text-align: center;
    border-radius: 7px;
    padding: 10px;
    margin-bottom: 1rem;
    box-shadow: 0 0 3px #575757;
    transition: transform .2s;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }

    &--title {
      font-size: 1.2rem;
    }
  }

  &__backend {
    margin: 0 2rem 0 2rem;
    width: 80%;
    background-color: #ededed;
    text-align: center;
    padding: 10px;
    border-radius: 7px;
    margin-bottom: 1rem;
    box-shadow: 0 0 3px #575757;
    transition: transform .2s;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }

    &--title {
      font-size: 1.2rem;
    }
  }

  &__tools {
    width: 80%;
    background-color: #ededed;
    text-align: center;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0 0 3px #575757;
    transition: transform .2s;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }

    &--title {
      font-size: 1.2rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .skills {

    &__languages, &__tools, &__backend { 
      width: 20%;
      max-width: 290px;
    }

    &__container {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}