* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #242424;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
}

