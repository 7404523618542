.contact {
  display: flex;
  flex-direction: column;
  color: #fff9f0;
  justify-content: center;
  align-items: center;
  padding: 8rem 0 16rem 0;

  &__error {
    color: red;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__input {
    height: 3rem;
    width: 20rem;
    text-align: center;
    margin: 1rem 0 0 0;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 0 3px #575757;

    &--message {
      height: 5rem;
      padding: 5px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      font-family: inherit;
      font-size: inherit;
      resize: none;
    }

    &--message::placeholder {
      transform: translateY(5px);
    }
  }

  &__submit {
    font-weight: 600;
    font-size: 1.2rem;
    transition: transform .2s;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
}