.about {
  color: #fff9f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4rem 0 4rem 0;

  &__title {
    margin: 2rem 0 2rem 0;
  }

  &__picture {
    height: 15rem;
    width: 15rem;
    margin: 0 0 2rem 0;
  }

  &__desc {
    padding: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .about {
    display: flex;

    &__container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &__desc-container {
      display: flex;
      flex-direction: column;
      width: 45%;
      max-width: 800px;
    }

  }
}