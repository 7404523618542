.timeline {
  font-family: 'Inter', sans-serif;
  padding: 2rem 0 2rem 0;

  &__img-container {
    margin: 1rem 0 1rem 0;
    min-height: 100px;
    min-width: 50px;
  }

  &__img {
    max-height: 250px;
    max-width: 80%;
    box-shadow: 0 0 15px #bdbdbd;
  }

  &__tech {
    margin: 1rem 0 1rem 0;
  }

  &__code, &__deploy {
    height: 1.6rem;
    width: 5rem;
    border: 0;
    background-color: #e0e0e0;
    border-radius: 5px;
    box-shadow: 0 0 4px gray;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }

  &__deploy {
    margin-left: 1rem;
  }
}

.vertical-timeline-element-subtitle {
  font-weight: 400;
  margin-bottom: 1rem;
}