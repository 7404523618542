.bird-container {
  overflow: hidden;
  width: 100%;
}

.bird{
  background:url("../images/replaceColor-small.png");
  width: 65px;
  height: 88px;
  position: absolute;
}

.bird-1{
  top: 20%;
  left: -25%;
  animation: birdFly 1.5s steps(10) infinite, birdMove 15s linear infinite;

}


.bird-2{
  top: 70%;
  left: -25%;
  animation: birdFly 1.4s steps(10) infinite, birdMove 15s linear infinite;
  animation-delay:4s;
}


.bird-3{
  top: 12%;
  left: -25%;
  animation: birdFly 1.6s steps(10) infinite, birdMove 15s linear infinite;
  animation-delay:9s;
}

@keyframes birdFly{
  0%{
    background-position-x: 0px;
 }

  100%{
   background-position-x: 1305px;
 }

}

@keyframes birdMove{
 0%{
   left: -10%;
 }

 100%{
   left: 110%;
 }

}

@media screen and (min-width: 768px) {
  .bird {
    background:url("../images/replaceColor-BG.png");
    width: 130px;
    height: 175px;
  }

  .bird-1{
    top: 10%;
    animation: birdFly 1s steps(10) infinite, birdMove 15s linear infinite;
  }
  
  
  .bird-2{
    top: 70%;
    animation: birdFly 1.05s steps(10) infinite, birdMove 15s linear infinite;
    animation-delay:4s;
  }
  
  
  .bird-3{
    top: 5%;
    animation: birdFly 1.03s steps(10) infinite, birdMove 15s linear infinite;
    animation-delay:9s;
  }
}